<template>
  <svg
    id="Laag_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 52 31.2"
    style="enable-background: new 0 0 52 31.2"
    xml:space="preserve"
  >
    <g>
      <defs>
        <rect id="SVGID_1_" x="-760" y="-619.5" width="1680" height="3080" />
      </defs>
      <clipPath id="SVGID_00000001658081101403058510000007396897137014292914_">
        <use xlink:href="#SVGID_1_" style="overflow: visible" />
      </clipPath>
      <g
        style="
          clip-path: url(#SVGID_00000001658081101403058510000007396897137014292914_);
        "
      >
        <g>
          <defs>
            <path
              id="SVGID_00000169537005791110713560000014486763037596723861_"
              d="M52.2,0.1c-2.3,0.2-4.3,0.7-6,1.4
					c-5,2.1-6.6,3.7-13.1,10.3c-4.6,4.6-7,6.8-9.1,9l-0.4,0.4c-0.5,0.5-0.9,1-1.4,1.5c-7.5,8.4-11.1,9.7-16.5,7.6
					c-3.7-1.4-5.5-5-5.5-11V0.1H52.2z"
            />
          </defs>
          <use
            xlink:href="#SVGID_00000169537005791110713560000014486763037596723861_"
            style="overflow: visible"
            fill="currentColor"
          />
          <clipPath
            id="SVGID_00000040555984192693235580000017295579286327630486_"
          >
            <use
              xlink:href="#SVGID_00000169537005791110713560000014486763037596723861_"
              style="overflow: visible"
            />
          </clipPath>
        </g>
      </g>
    </g>
  </svg>
</template>

<style type="text/css">
.st0 {
  clip-path: url(#SVGID_00000126321258839357774280000005054822666086368943_);
}
</style>
