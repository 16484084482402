export function useApiForm() {
  const { create } = useStrapi()

  async function createForm(data: any) {
    const response = await create('form', data)
    return response.data
  }

  return {
    createForm,
  }
}
