<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M22.0400283,11.2341626 L22.4470222,9.6858633 C22.8140408,8.28963928 21.9570725,6.8424087 20.5329632,6.45343945 L8.24399387,3.09693357 C6.81988457,2.70796432 5.3678983,3.52454644 5.00087971,4.92077046 L1.23319069,19.2539353 L0.0289277777,23.9378153 C-0.169061518,24.7077333 0.689531611,25.3418945 1.3771331,24.9336867 L4.0288783,23.3593941 C4.49471557,23.0828422 5.05880803,23.0084662 5.5900366,23.1535615 L26.6403427,28.9030653 C28.064452,29.2920345 29.5164937,28.4754675 29.8835123,27.0792435 L31.9150969,19.3506236 C32.2821297,17.9543453 31.4251472,16.507169 30.0010379,16.1181998 L23.9540873,14.4665865 C22.529978,14.0776172 21.6730098,12.6303866 22.0400283,11.2341626"
    />
  </svg>
</template>
