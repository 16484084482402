<script setup>
// Props
const props = defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
})

function randomClass(slide, index) {
  const classes = props.content?.noCutout
    ? ['']
    : ['', 'mask-tl', 'mask-tr', 'mask-br', 'mask-bl']

  let randomClass = ''

  if (!slide.title) {
    randomClass = classes[index % classes.length]
  } else {
    randomClass = 'mask-br'
  }

  switch (slide?.orientation) {
    case 'Landscape':
      return randomClass + ' w-[512px] lg:w-[624px]'
    default:
      return randomClass + ' w-[256px] lg:w-[312px]'
  }
}

function addLineBreakAfterFirstWord(text) {
  const firstSpace = text.indexOf(' ')
  if (firstSpace === -1) return text
  return text.slice(0, firstSpace) + '<br>' + text.slice(firstSpace + 1)
}
</script>

<template>
  <section
    class="py-10 overflow-hidden lg:py-20"
    :class="{
      'bg-primary text-gray-700': content.style === 'Primary',
      'bg-secondary text-gray-100': content.style === 'Secondary',
      'bg-tertiary text-gray-700': content.style === 'Tertiary',
      'bg-white text-gray-700': content.style === 'White',
    }"
  >
    <BaseContainer v-if="content?.title">
      <div class="lg:max-w-1/2 relative z-10">
        <header class="mb-10 text-center lg:mb-16">
          <h2
            class="text-5xl uppercase leading-verytight lg:text-7xl"
            :class="{
              'text-secondary': ['Primary', 'Tertiary', 'White'].includes(
                content?.style,
              ),
              'text-primary': content?.style === 'Secondary',
            }"
          >
            {{ content?.title }}
          </h2>
        </header>
      </div>
    </BaseContainer>

    <div
      :class="{
        'mt-10 lg:mt-20': content?.title,
      }"
    >
      <ClientOnly>
        <Vue3Marquee
          v-if="content?.items.length"
          :duration="content?.speed || 30"
          :direction="content?.reverse ? 'reverse' : 'normal'"
        >
          <div
            class="mr-4 flex gap-x-4 sm:mr-6 sm:gap-x-6 lg:mr-10 lg:gap-x-10"
          >
            <div
              v-for="(slide, index) of content.items"
              :key="index"
              class="h-[380px] lg:h-[420px]"
            >
              <div
                class="relative h-[380px] overflow-hidden rounded-2xl lg:h-[420px] lg:rounded-3xl"
                :class="randomClass(slide, index)"
              >
                <BaseVisual :file="slide?.visual" size="large" cover lazy />
              </div>
              <header
                v-if="slide?.title"
                class="-mt-[150px] pl-6"
                :class="{
                  'ml-[128px] lg:ml-[184px]': slide?.orientation === 'Portrait',
                  'ml-[384px] lg:ml-[496px]':
                    slide?.orientation === 'Landscape',
                }"
              >
                <!-- eslint-disable vue/no-v-html -->
                <h3
                  class="mt-6 pr-6 text-6xl-nlh uppercase !leading-[0.75] text-secondary lg:mt-4 lg:text-7xl-nlh"
                  v-html="addLineBreakAfterFirstWord(slide?.title)"
                ></h3>
                <!-- eslint-enable vue/no-v-html -->
              </header>
            </div>
          </div>
        </Vue3Marquee>
      </ClientOnly>
    </div>
  </section>
</template>
