<script setup>
defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
})

const { formatMarkdown } = useFormatters()
const swiper = ref(null)

const currentSlide = computed(() => swiper.value?.activeIndex)
const maxSlides = computed(() => swiper.value?.slides.length)

function initializeSwiper(swiperInstance) {
  swiper.value = swiperInstance
}
</script>

<template>
  <section class="overflow-hidden bg-secondary py-10 lg:py-20 pb-6 lg:pb-12">
    <BaseContainer size="md">
      <div class="lg:max-w-1/2 relative z-10">
        <header v-if="content?.title" class="mb-10 text-center lg:mb-16">
          <h2
            class="text-5xl-nlh uppercase leading-verytight text-primary lg:text-7xl-nlh"
          >
            {{ content?.title }}
          </h2>
        </header>
      </div>

      <Swiper
        v-if="content?.items && content?.items.length"
        :space-between="40"
        grab-cursor
        class="!overflow-visible"
        @swiper="initializeSwiper"
      >
        <SwiperSlide v-for="(item, index) in content?.items" :key="index">
          <div
            :index="index + 1"
            class="flex flex-col items-center md:flex-row"
          >
            <figure
              class="relative h-[320px] w-full max-w-full overflow-hidden rounded-t-2xl md:hidden"
            >
              <BaseVisual
                :file="item?.visual"
                class="w-full bg-white"
                size="medium"
                cover
                :width="item?.visual?.ext === '.svg' ? '100%' : null"
                :height="item?.visual?.ext === '.svg' ? '100%' : null"
              />
            </figure>
            <div
              class="flex items-center gap-x-10 rounded-2xl rounded-t-none bg-stone-200 p-6 md:rounded-t-2xl md:p-10 lg:rounded-3xl"
            >
              <figure
                class="relative hidden h-[344px] w-full max-w-[256px] overflow-hidden rounded-2xl md:flex"
                :class="{ 'mask-br': !item.noCutout }"
              >
                <BaseVisual
                  :file="item?.visual"
                  class="w-full bg-white"
                  size="large"
                  cover
                  :width="item?.visual?.ext === '.svg' ? '100%' : null"
                  :height="item?.visual?.ext === '.svg' ? '100%' : null"
                />
              </figure>
              <div class="flex w-full flex-col">
                <header class="mb-4">
                  <h2
                    class="text-3xl-nlh text-secondary md:text-5xl-nlh uppercase"
                  >
                    &quot;{{ item?.title }}&quot;
                  </h2>
                </header>

                <!-- eslint-disable vue/no-v-html -->
                <div
                  v-if="item?.content"
                  class="mb-4 text-sm text-gray-600"
                  v-html="formatMarkdown(item.content)"
                ></div>
                <!-- eslint-enable vue/no-v-html -->

                <footer
                  class="flex w-full flex-col justify-between md:flex-row"
                >
                  <div class="flex w-full">
                    <div
                      class="flex w-full items-center justify-between gap-x-6"
                    >
                      <div>
                        <h3 class="font-sans text-base-nlh text-secondary">
                          {{ item?.name }}
                        </h3>
                        <h4 class="font-sans text-sm text-gray-600">
                          {{ item?.position }}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4 md:mt-0">
                    <div class="flex lg:h-full lg:items-center">
                      <Button
                        v-if="item?.link"
                        theme="accent"
                        :path="item?.link?.url"
                        :aria-label="item?.link?.title"
                        :text="item?.link?.title"
                      >
                      </Button>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div class="flex justify-center gap-2 mt-4">
        <button
          class="flex h-10 w-10 items-center justify-center rounded-full"
          :class="{
            'bg-gray-500 text-white cursor-default': currentSlide === 0,
            'bg-primary text-secondary hover:bg-stone-200': currentSlide !== 0,
          }"
          @click="swiper.slidePrev()"
        >
          <IconArrow direction="left" class="h-4 w-4" />
        </button>
        <button
          class="flex h-10 w-10 items-center justify-center rounded-full"
          :class="{
            'bg-gray-500 text-white cursor-default':
              currentSlide === maxSlides - 1,
            'bg-primary text-secondary hover:bg-stone-200':
              currentSlide !== maxSlides - 1,
          }"
          @click="swiper.slideNext()"
        >
          <IconArrow direction="right" class="h-4 w-4" />
        </button>
      </div>
    </BaseContainer>
  </section>
</template>
