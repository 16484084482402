<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
})

const { formatDate } = useFormatters()
const { getPosts } = useApiPost()

const { data: posts } = await useAsyncData('posts', async () => {
  if (props.content.posts) {
    return props.content.posts
  } else {
    return await getPosts()
  }
})
</script>

<template>
  <section v-if="posts?.length">
    <BaseContainer class="my-10 lg:my-20">
      <div class="lg:max-w-1/2 relative z-10">
        <header v-if="content?.title" class="mb-10 text-center lg:mb-16">
          <h2
            class="text-5xl-nlh uppercase leading-verytight text-secondary lg:text-7xl-nlh"
          >
            {{ content?.title }}
          </h2>
        </header>
      </div>
      <div
        class="grid gap-x-3 gap-y-5 sm:gap-x-10 sm:gap-y-10 grid-cols-1"
        :class="{
          'sm:grid-cols-2 lg:grid-cols-2': posts.length === 2,
          'sm:grid-cols-2 lg:grid-cols-3': posts.length > 2,
        }"
      >
        <BaseCard
          v-for="(post, index) of posts"
          :key="index"
          :truncate="false"
          :content="{
            visual: post?.image || null,
            ...post,
            subTitle: formatDate(post?.publishedAt),
            content: post?.excerpt || '',
            link: {
              title: 'Lees artikel',
              url: '/blog/' + post?.slug,
            },
          }"
          :class="{ 'last:sm:hidden last:lg:flex': posts?.length > 2 }"
        />
      </div>

      <footer
        v-if="content?.button"
        class="relative z-10 mt-6 flex flex-wrap justify-end gap-3 sm:gap-5 lg:mt-16"
      >
        <Button
          :key="content?.button.id"
          :theme="content?.button?.style.toLowerCase() || 'primary'"
          :path="content?.button?.url"
          :aria-label="content?.button?.title"
          :text="content?.button?.title"
        />
      </footer>
    </BaseContainer>
  </section>
</template>
