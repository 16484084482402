<script setup>
defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
})

const { formatMarkdown } = useFormatters()

const carouselModelValue = ref(0)

function goToSlide(slideNumber) {
  carouselModelValue.value = slideNumber
}
</script>

<template>
  <div class="my-10 md:my-20">
    <BaseContainer size="sm">
      <div class="mb-10 md:mb-20 -mx-4 sm:-mx-6">
        <Carousel
          v-if="content?.items && content?.items.length"
          v-model="carouselModelValue"
          wrap-around
        >
          <Slide
            v-for="(teamMember, key) in content?.items"
            :key="key"
            class="cursor-grab px-4 sm:px-6"
          >
            <div
              class="flex flex-col items-center gap-x-12 gap-y-6 md:gap-y-12 font-sans sm:flex-row"
            >
              <figure
                class="mask-br relative flex h-72 w-full max-w-full shrink-0 overflow-hidden rounded-2xl sm:h-[400px] sm:w-[312px] lg:rounded-3xl"
              >
                <BaseVisual
                  v-if="teamMember.visual"
                  :file="teamMember?.visual"
                  class="w-full bg-white"
                  size="large"
                  cover
                  width="312"
                  height="400px"
                  type="image"
                />
              </figure>

              <div class="flex h-auto flex-col gap-y-1">
                <div
                  class="relative flex flex-col text-[87px] font-extrabold uppercase leading-none text-white"
                >
                  <div class="flex bg-white">
                    <div
                      class="rounded-t-2xl bg-secondary p-4 pb-0 relative z-10"
                    >
                      <h2
                        class="-mb-6 text-[66px] leading-verytight sm:text-[87px]"
                      >
                        Hey
                      </h2>
                    </div>

                    <div class="w-full bg-secondary">
                      <div class="h-full w-full rounded-bl-2xl bg-white"></div>
                    </div>
                  </div>

                  <div class="inline-flex">
                    <div class="rounded-r-2xl bg-secondary">
                      <h2
                        class="pt-6 pb-4 pl-[17px] pr-6 text-[66px] leading-[0.8] sm:text-[87px]"
                      >
                        ik ben {{ teamMember?.firstName }}
                      </h2>
                    </div>
                  </div>

                  <IconMessageCorner
                    class="-mt-[1px] h-7 text-secondary mr-auto"
                  />
                </div>

                <div class="flex flex-col justify-end grow py-4">
                  <!-- eslint-disable vue/no-v-html -->
                  <div
                    class="text-base text-gray-700"
                    v-html="formatMarkdown(teamMember?.description)"
                  ></div>
                  <!-- eslint-enable vue/no-v-html -->
                </div>

                <div class="md:flex items-center justify-between gap-x-4">
                  <div>
                    <p class="font-sans text-lg font-extrabold text-secondary">
                      {{ `${teamMember.firstName} ${teamMember?.lastName}` }}
                    </p>
                    <!-- eslint-disable vue/no-v-html -->
                    <div
                      v-if="teamMember.position"
                      class="font-medium text-gray-600"
                      v-html="formatMarkdown(teamMember.position)"
                    ></div>
                    <!-- eslint-enable vue/no-v-html -->
                  </div>
                  <div
                    v-if="content?.items && content?.items.length > 1"
                    class="flex gap-x-3 md:gap-x-6 mt-4 md:mt-0"
                  >
                    <div
                      class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-secondary text-white"
                      @click="goToSlide(carouselModelValue - 1)"
                    >
                      <IconArrow direction="left" />
                    </div>
                    <div
                      class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-secondary text-white"
                      @click="goToSlide(carouselModelValue + 1)"
                    >
                      <IconArrow />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        </Carousel>
      </div>

      <div class="mt grid grid-cols-1 gap-12 lg:grid-cols-2">
        <div
          v-for="(teamMember, key) in content?.items"
          :key="key"
          class="flex items-start md:items-center gap-x-6 md:gap-x-12 font-sans"
        >
          <figure
            class="mask-br mask-br__sm relative flex h-[140px] w-[104px] max-w-full overflow-hidden rounded-lg shrink-0"
          >
            <BaseVisual
              v-if="teamMember.visual"
              :file="teamMember?.visual"
              class="w-full bg-white"
              size="large"
              cover
              width="104px"
              height="140px"
              type="image"
            />
          </figure>
          <div class="flex flex-col gap-y-1">
            <p class="text-2xl font-extrabold text-secondary">
              {{ `${teamMember.firstName} ${teamMember?.lastName}` }}
            </p>

            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="teamMember.position"
              class="text-base text-gray-700"
              v-html="formatMarkdown(teamMember.position)"
            ></div>
            <!-- eslint-enable vue/no-v-html -->
            <p class="text-base text-secondary">
              {{ teamMember.emailAddress }}
            </p>
          </div>
        </div>
      </div>
    </BaseContainer>
  </div>
</template>

<style scoped>
.mask-br__sm {
  mask-size: 650px;
}

.mask-br__lg {
  mask-size: 1500px;
}
</style>
