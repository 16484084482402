<script setup>
defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
})

const { formatMarkdown } = useFormatters()

const expandedStep = ref(null)

function expandStep(stepNumber) {
  if (stepNumber === expandedStep.value) {
    expandedStep.value = null
  } else {
    expandedStep.value = stepNumber
  }
}
</script>

<template>
  <section class="relative bg-primary py-10">
    <BaseContainer>
      <div class="flex flex-col-reverse items-center md:flex-row-reverse">
        <div
          class="z-10 flex w-full flex-col items-end gap-y-5 pb-10 md:max-w-[624px] md:shrink-0 md:pb-24"
        >
          <h3
            class="mb-4 mt-6 font-mono text-2xl-nlh uppercase text-secondary md:mb-16"
          >
            {{ content?.title }}
          </h3>
          <div
            v-for="(step, index) in content?.items"
            :key="index"
            class="flex w-full flex-col rounded-2xl bg-secondary p-4 text-white lg:rounded-3xl lg:p-8"
          >
            <div class="flex items-center justify-between gap-x-4">
              <div
                class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-accent text-center font-heading text-xl text-secondary lg:h-14 lg:w-14 lg:text-[32px]"
              >
                <span class="pt-1">{{ index + 1 }}</span>
              </div>

              <p
                class="pt-2 font-heading text-4xl uppercase text-white sm:text-5xl"
                @click="expandStep(index)"
              >
                {{ step?.title }}
              </p>

              <div
                class="z-10 ml-auto flex h-10 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-accent text-secondary"
                @click="expandStep(index)"
              >
                <IconArrow :class="[{ 'rotate-90': index === expandedStep }]" />
              </div>
            </div>

            <HeightTransition>
              <div v-if="index === expandedStep" class="sm:pl-18">
                <!-- eslint-disable vue/no-v-html -->
                <div
                  class="text-sans pt-4 text-sm text-white sm:text-base"
                  v-html="formatMarkdown(step?.content)"
                ></div>
                <!-- eslint-enable vue/no-v-html -->

                <Button
                  v-if="step?.link && step?.link?.url"
                  theme="accent"
                  :text="step?.link?.title || 'Lees meer'"
                  :path="step?.link?.url"
                  :aria-label="step?.link?.title"
                  class="mt-6 w-fit"
                />
              </div>
            </HeightTransition>
          </div>
        </div>
        <figure
          class="relative z-0 flex h-96 w-full -translate-x-0 overflow-hidden rounded-2xl md:absolute md:bottom-16 md:left-0 md:right-[45vw] md:top-16 md:h-auto md:w-auto md:translate-x-0 md:rounded-l-none md:rounded-r-[56px]"
        >
          <BaseVisual
            v-if="content.visual"
            :file="content?.visual"
            class="w-full bg-white"
            size="large"
            cover
            width="100%"
            type="image"
            :autoplay="false"
          />
        </figure>
      </div>
    </BaseContainer>
  </section>
</template>
