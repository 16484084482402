<script setup>
defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
})

const { formatMarkdown } = useFormatters()

const openedQuestions = ref([])

function toggleQuestion(question) {
  if (openedQuestions.value.find((item) => item.id === question.id)) {
    openedQuestions.value = openedQuestions.value.filter(
      (item) => item.id !== question.id,
    )
  } else {
    openedQuestions.value.push(question)
  }
}

function isActive(question) {
  return openedQuestions.value.find((item) => item.id === question.id)
}
</script>

<template>
  <section class="w-full">
    <BaseContainer class="my-10 lg:my-20" size="md">
      <div class="rounded-2xl bg-stone-200 p-6 sm:p-12 lg:rounded-3xl">
        <div v-if="content?.title" class="lg:max-w-1/2 relative z-10">
          <header class="mb-10 text-center">
            <h2
              class="text-5xl-nlh uppercase leading-verytight text-secondary lg:text-7xl-nlh"
            >
              {{ content?.title }}
            </h2>
          </header>
        </div>

        <div class="flex items-center justify-center">
          <div
            v-if="content?.items && content?.items.length"
            class="flex w-full max-w-3xl flex-col gap-y-4"
          >
            <div
              v-for="(item, index) of content.items"
              :key="index"
              class="flex flex-col rounded-2xl bg-white p-4 sm:p-6"
            >
              <h3
                class="flex cursor-pointer items-center gap-x-4 font-sans text-base text-gray-800 lg:text-lg"
                @click="toggleQuestion(item)"
              >
                <span>{{ item?.question }}</span>
                <span
                  class="ml-auto flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-secondary transition-all lg:h-10 lg:w-10"
                  :class="{ '-rotate-90': isActive(item) }"
                >
                  <IconChevronRight class="h-2 w-2 text-white lg:h-3 lg:w-3" />
                </span>
              </h3>

              <HeightTransition>
                <div v-if="isActive(item)">
                  <!-- eslint-disable vue/no-v-html -->
                  <div
                    v-if="item?.answer"
                    class="mt-4 text-sm leading-6 text-gray-600"
                    v-html="formatMarkdown(item.answer)"
                  ></div>
                  <!-- eslint-enable vue/no-v-html -->
                </div>
              </HeightTransition>
            </div>
          </div>
        </div>
      </div>
    </BaseContainer>
  </section>
</template>
