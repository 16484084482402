<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },

  grouped: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const { formatMarkdown } = useFormatters()

const visualIsVideo = computed(() =>
  props.content?.visual?.mime.includes('video'),
)
</script>

<template>
  <section
    class="relative"
    :class="{
      flex: !grouped,
      'bg-primary text-gray-700': content.style === 'Primary',
      'bg-secondary text-gray-100': content.style === 'Secondary',
      'bg-gray-100 text-gray-700': content.style === 'Tertiary',
      'bg-white text-gray-700': content.style === 'White',
    }"
  >
    <BaseContainer
      class="relative z-10 flex"
      :size="
        visualIsVideo && content?.direction !== 'Center' ? 'default' : 'sm'
      "
    >
      <div
        class="my-12 w-full justify-between gap-x-8 md:my-16 md:flex lg:my-20 lg:gap-x-16"
        :class="{
          'flex-row-reverse': content?.direction === 'Left' && content?.visual,
          'flex-col': content?.direction === 'Center',
          'items-center': content?.visual,
        }"
      >
        <div
          v-if="content?.visual"
          class="mb-8 flex w-full md:mb-0"
          :class="{
            'md:justify-end': content?.direction === 'Left',
            'md:mb-0 lg:max-w-[510px]':
              content?.direction !== 'Center' && !visualIsVideo,
            'md:justify-center lg:mb-16': content?.direction === 'Center',
          }"
        >
          <div
            class="overflow-hidden rounded-2xl lg:rounded-3xl"
            :class="{ 'mask-br': !visualIsVideo && !content.noCutout }"
          >
            <BaseVisual
              v-if="!visualIsVideo"
              :file="content?.visual"
              class="w-full bg-white md:hidden"
              size="large"
              :width="content?.visual?.ext === '.svg' ? '100%' : null"
              :height="content?.visual?.ext === '.svg' ? '100%' : null"
            />
            <BaseVisual
              :file="content?.visual"
              class="w-full bg-white md:flex"
              :class="{ hidden: !visualIsVideo }"
              size="large"
              :width="content?.visual?.ext === '.svg' ? '100%' : null"
              :height="content?.visual?.ext === '.svg' ? '100%' : null"
              :autoplay="visualIsVideo ? false : true"
            />
          </div>
        </div>
        <div
          v-else
          class="mb-8 flex w-full"
          :class="{
            'md:mb-0 md:justify-end': content?.direction === 'Left',
            'md:mb-0 lg:max-w-[510px]': content?.direction !== 'Center',
            'md:justify-center': content?.direction === 'Center',
          }"
        >
          <header
            :class="{
              'md:text-center': content?.direction === 'Center',
            }"
          >
            <h3
              v-if="content?.subTitle"
              class="mb-4 font-sans text-base-nlh font-bold uppercase text-gray-600"
            >
              {{ content?.subTitle }}
            </h3>
            <h2
              v-if="content?.title"
              class="text-4xl-nlh uppercase leading-none lg:text-6xl-nlh"
              :class="{
                'max-w-[510px] text-4xl-nlh md:mx-auto lg:text-6xl-nlh':
                  content?.direction === 'Center',
                'text-secondary': ['Primary', 'Tertiary', 'White'].includes(
                  content?.style,
                ),
                'text-primary': content?.style === 'Secondary',
              }"
            >
              {{ content?.title }}
            </h2>
          </header>
        </div>

        <div
          class="w-full"
          :class="{
            'lg:max-w-[510px]':
              content?.direction !== 'Center' && !visualIsVideo,
          }"
        >
          <header
            v-if="content?.visual"
            :class="{
              'md:text-center': content?.direction === 'Center',
            }"
          >
            <h3
              v-if="content?.subTitle"
              class="mb-4 font-sans text-base-nlh font-bold uppercase text-gray-600"
            >
              {{ content?.subTitle }}
            </h3>
            <h2
              v-if="content?.title"
              class="mb-4 text-4xl-nlh uppercase leading-verytight lg:text-6xl-nlh"
              :class="{
                'max-w-[510px] text-4xl-nlh md:mx-auto lg:text-6xl-nlh':
                  content?.direction === 'Center',
                'text-secondary': ['Primary', 'Tertiary', 'White'].includes(
                  content?.style,
                ),
                'text-primary': content?.style === 'Secondary',
              }"
            >
              {{ content?.title }}
            </h2>
          </header>

          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="content?.content"
            class="markdown prose mb-8 lg:text-lg"
            :class="{
              'text-gray-700': ['Primary', 'Tertiary', 'White'].includes(
                content?.style,
              ),
              'text-gray-400': content?.style === 'Secondary',
              'mt-4': content?.visual,
              'lg:pt-8':
                content?.subTitle &&
                !content?.visual &&
                content?.direction !== 'Center',
              'md:columns-2 md:gap-16':
                content?.direction === 'Center' &&
                content?.content?.length > 300,
              'md:text-center':
                content?.direction === 'Center' &&
                content?.content?.length <= 300,
            }"
            v-html="formatMarkdown(content.content)"
          ></div>
          <!-- eslint-enable vue/no-v-html -->

          <footer
            v-if="content?.button?.length"
            class="relative z-10 flex w-full flex-wrap gap-3 !gap-y-2 sm:gap-5"
            :class="{
              'md:justify-center': content?.direction === 'Center',
            }"
          >
            <Button
              v-for="button in content?.button"
              :key="button.id"
              :theme="button?.style.toLowerCase() || 'primary'"
              size="md"
              :path="button?.url"
              :aria-label="button?.title"
              :text="button?.title"
              class="shrink-0 grow"
            />
          </footer>
        </div>
      </div>
    </BaseContainer>
  </section>
</template>
