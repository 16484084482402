<script setup>
import { reset } from '@formkit/core'
const loading = ref(false)
const router = useRouter()
const client = useStrapiClient()
const { formatMarkdown } = useFormatters()

const message = ref({
  status: '',
  text: '',
})

const props = defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
})
const form = computed(() => props?.content?.form)

const computedFields = computed(() => {
  return form?.value?.formField?.reduce((acc, field) => {
    const fieldName = field?.label?.toLowerCase()
    return {
      ...acc,
      [fieldName]: {
        label:
          field?.inputType === 'checkbox'
            ? null
            : field?.label?.charAt(0).toUpperCase() + field?.label?.slice(1),
        type: field?.inputType,
        validation: field?.required ? 'required' : '',
        placeholder:
          field?.label?.charAt(0).toUpperCase() + field?.label?.slice(1),
        class:
          field?.inputType === 'checkbox' || field?.inputType === 'radio'
            ? { label: 'ml-1.5' }
            : '',
        options:
          field?.inputType === 'checkbox'
            ? [field?.label]
            : field?.inputType === 'radio'
              ? field?.options
              : null,
      },
    }
  }, {})
})

async function submit(formData) {
  loading.value = true

  const request = {
    data: {
      formName: form?.value?.name,
      data: { formId: form?.value?.id, ...formData },
    },
  }

  try {
    const res = await client('/submissions', {
      method: 'POST',
      body: request,
    })
    if (res && res.data && res.data.id) {
      reset(`${form?.value?.title}`)
      message.value = {
        status: 'success',
        text: 'Uw bericht is verstuurd!',
      }
    }
  } catch (error) {
    message.value = {
      status: 'error',
      text: 'Er ging iets mis, probeer het later opnieuw.',
    }
  } finally {
    loading.value = false
  }

  if (form?.value?.redirect?.slug) {
    await router.push({
      path: form?.value?.redirect?.slug,
    })
  }
}
</script>

<template>
  <section>
    <BaseContainer class="my-10 lg:my-20" size="md">
      <div class="rounded-2xl bg-stone-200 p-10 lg:rounded-3xl">
        <header v-if="form?.title || form?.subTitle" class="mb-3">
          <h2
            v-if="form?.title"
            class="text-4xl-nlh uppercase leading-verytight text-secondary lg:text-6xl-nlh"
          >
            {{ form?.title }}
          </h2>
        </header>

        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="form?.content"
          class="text-gray-800"
          v-html="formatMarkdown(form?.content)"
        ></div>
        <!-- eslint-enable vue/no-v-html -->

        <BaseForm
          :loading="loading"
          :message="message"
          class="mt-12"
          :config="{
            form: form?.id,
            fields: computedFields,
            submit: {
              label: 'Verstuur',
              type: 'submit',
              class: 'lg:col-span-2',
            },
          }"
          @handle-submit="submit"
        />
      </div>
    </BaseContainer>
  </section>
</template>
