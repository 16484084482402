<script setup>
defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
})

const { formatMarkdown } = useFormatters()
</script>

<template>
  <section class="relative w-full">
    <BaseContainer class="my-10 lg:my-20">
      <div
        v-if="content?.title"
        class="w-full items-center justify-between gap-x-8 md:flex lg:gap-x-16"
        :class="{
          'flex-row-reverse': content?.direction === 'Right',
          'flex-col': content?.direction === 'Center',
        }"
      >
        <div
          class="mb-6 w-full md:mb-0"
          :class="{
            'lg:pr-40': content?.direction === 'Left',
            'lg:pl-40': content?.direction === 'Right',
          }"
        >
          <header
            class="mb-4"
            :class="{ 'text-center': content?.direction === 'Center' }"
          >
            <h2
              class="text-6xl-nlh uppercase leading-verytight text-secondary lg:text-8xl-nlh"
            >
              {{ content?.title }}
            </h2>
          </header>
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="content?.content"
            class="w-full text-gray-700 lg:text-lg flex flex-col gap-3"
            :class="{ 'mb-10 text-center': content?.direction === 'Center' }"
            v-html="formatMarkdown(content.content)"
          ></div>
          <!-- eslint-enable vue/no-v-html -->
        </div>

        <div
          class="w-full rounded-2xl bg-secondary p-6 lg:rounded-3xl lg:p-10"
          :class="{ 'max-w-2xl': content?.direction === 'Center' }"
        >
          <div
            v-if="content?.items && content?.items.length"
            class="flex flex-col gap-x-6 gap-y-6 lg:gap-y-10"
          >
            <div
              v-for="(item, index) of content.items"
              :key="index"
              class="flex items-center justify-between"
            >
              <h3
                class="-mb-3 w-full text-6xl-nlh uppercase text-accent lg:-mb-4 lg:text-8xl-nlh"
              >
                {{ item?.title }}
              </h3>
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="item?.content"
                class="w-full text-gray-400 lg:text-lg"
                v-html="formatMarkdown(item.content)"
              ></div>
              <!-- eslint-enable vue/no-v-html -->
            </div>
          </div>
        </div>
      </div>
    </BaseContainer>
  </section>
</template>
