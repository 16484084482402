<script setup>
defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
})

const { formatMarkdown } = useFormatters()
</script>

<template>
  <section>
    <BaseContainer
      :size="content?.columns < 3 ? 'sm' : 'default'"
      class="my-12 md:my-16"
    >
      <header v-if="content?.title" class="markdown prose mb-3">
        <h2 class="font-sans text-secondary">{{ content?.title }}</h2>
      </header>
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="content?.content"
        class="markdown prose"
        v-html="formatMarkdown(content.content)"
      ></div>
      <!-- eslint-enable vue/no-v-html -->
      <div
        class="mt-12 grid gap-x-3 gap-y-5 sm:gap-x-5 sm:gap-y-10"
        :class="{
          'grid-cols-1 sm:grid-cols-2': content?.columns === 2,
          'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3': content?.columns > 2,
        }"
      >
        <div v-for="(item, index) of content.items" :key="index">
          <div class="flex gap-x-3 sm:gap-x-5">
            <div v-if="item?.icon" class="relative h-14 w-14">
              <BaseVisual
                v-if="item?.icon"
                :file="item?.icon"
                class="relative z-10 flex h-14 w-14 items-center justify-center rounded-2xl bg-gray-200 p-2 text-white"
                :width="32"
                :height="32"
                size="thumbnail"
              />
            </div>

            <div class="w-full">
              <header class="markdown prose mb-2">
                <h4 class="font-sans text-secondary">{{ item?.title }}</h4>
              </header>
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="item?.content"
                class="markdown prose"
                v-html="formatMarkdown(item.content)"
              ></div>
              <!-- eslint-enable vue/no-v-html -->
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="content?.buttons"
        class="relative z-10 flex flex-wrap gap-3 sm:gap-5"
      >
        <Button
          v-for="button in content?.buttons"
          :key="button.id"
          :theme="button?.style.toLowerCase() || 'primary'"
          :path="button?.url"
          :aria-label="button?.title"
          :text="button?.title"
        />
      </div>
    </BaseContainer>
  </section>
</template>
