<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
})

const { splitToChunks } = useHelpers()

const chunks = computed(() => splitToChunks(props.content?.visuals, 3))
</script>

<template>
  <section
    class="overflow-hidden"
    :class="{
      'bg-primary': content?.style === 'Primary',
      'bg-secondary': content?.style === 'Secondary',
      'bg-gray-100 ': content?.style === 'Tertiary',
      'cursor-pointer': content?.url,
    }"
    @click="content?.url ? navigateTo(content?.url) : null"
  >
    <div class="my-10 lg:my-20">
      <BaseContainer size="md">
        <div class="lg:max-w-1/2 relative z-10">
          <header
            v-if="content?.title"
            class="mb-10 text-center lg:mb-16"
            :class="{
              'text-secondary': content?.style === 'Primary',
              'text-primary': content?.style === 'Secondary',
              'text-secondary ': content?.style === 'Tertiary',
            }"
          >
            <h2 class="text-5xl-nlh uppercase lg:text-7xl-nlh">
              {{ content?.title }}
            </h2>
          </header>
        </div>
      </BaseContainer>

      <ClientOnly>
        <Vue3Marquee
          v-for="(chunk, index) of chunks"
          :key="index"
          class="my-4 sm:my-5"
          :direction="index % 2 === 0 ? 'reverse' : 'normal'"
          :duration="60"
          clone
        >
          <div class="mr-4 flex w-full gap-x-4 md:mr-5 md:gap-x-5">
            <div
              v-for="visual of chunk"
              :key="visual.id"
              class="h-[80px] w-[160px] shrink-0 rounded-2xl bg-tertiary px-8 py-4 md:h-[160px] md:w-[320px] md:px-12 md:py-6 lg:rounded-3xl"
            >
              <BaseVisual
                class="relative h-full w-full text-primary mix-blend-multiply"
                :file="visual"
                size="small"
                contain
                lazy
                :width="visual?.ext === '.svg' ? '100%' : null"
                :height="visual?.ext === '.svg' ? '100%' : null"
              />
            </div>
          </div>
        </Vue3Marquee>
      </ClientOnly>
    </div>
  </section>
</template>
