<script setup>
defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },

  grouped: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const { formatMarkdown, formatPrice } = useFormatters()
</script>

<template>
  <section class="relative" :class="{ 'flex bg-tertiary': !grouped }">
    <BaseContainer
      class="relative z-10 my-10"
      :class="{ 'lg:my-[96px]': grouped, 'lg:my-20': !grouped }"
    >
      <div
        v-if="content?.items && content?.items.length"
        class="relative z-10 flex flex-wrap justify-center gap-y-4 sm:-mx-5 sm:gap-y-10"
      >
        <div
          v-for="(plan, index) of content.items"
          :key="index"
          class="w-full sm:w-1/2 sm:px-5 lg:w-1/3"
        >
          <BaseCard
            class="h-full border-[3px]"
            :class="{
              'relative border-accent sm:col-span-2 lg:col-span-1':
                plan.isRecommended,
              'border-transparent': !plan.isRecommended,
            }"
            theme="white"
          >
            <div
              v-if="plan.isRecommended"
              class="absolute right-6 top-0 flex rounded-b-xl bg-accent px-8 py-3 text-center text-xs font-bold text-gray-800 sm:right-8 lg:right-auto lg:left-1/2 lg:-translate-x-1/2 lg:-translate-y-full lg:rounded-b-none lg:rounded-t-2xl"
            >
              Aanbevolen
            </div>
            <header>
              <h2
                v-if="plan?.name"
                class="mb-2 flex items-start text-xl-nlh uppercase text-secondary"
              >
                <span>{{ plan?.name }}</span>
                <IconCloud
                  class="ml-4 -mt-4 h-8 w-8"
                  :class="{
                    'text-primary': plan?.rating === 1,
                    'text-[#FF6B6B]': plan?.rating === 2,
                    'text-accent': plan?.rating === 3,
                  }"
                />
              </h2>
              <p v-if="plan?.price || plan?.price === 0">
                <span
                  class="font-heading text-4xl font-bold text-secondary lg:text-6xl"
                >
                  {{ formatPrice(plan?.price) }},-
                </span>
              </p>
            </header>

            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="plan?.content"
              class="mt-4 text-sm"
              v-html="formatMarkdown(plan.content)"
            ></div>
            <!-- eslint-enable vue/no-v-html -->

            <ul v-if="plan?.items && plan?.items.length" class="my-4">
              <li
                v-for="(item, listItem) of plan?.items"
                :key="listItem"
                class="my-4 flex gap-x-3 text-sm"
              >
                <div
                  class="flex h-5 w-5 flex-shrink-0 items-center justify-center rounded bg-gray-300"
                >
                  <IconChecked
                    v-if="item.isIncluded"
                    class="h-3 w-3 text-secondary"
                  />
                </div>
                <span
                  class="font-bold"
                  :class="{ 'text-secondary': item.isIncluded }"
                >
                  {{ item?.title }}
                </span>
              </li>
            </ul>
            <footer v-if="plan?.button" class="relative z-10 mt-auto flex">
              <Button
                theme="accent"
                :path="plan?.button?.url"
                class="w-full"
                :aria-label="plan?.button?.title || 'Lees meer'"
                :text="plan?.button?.title || 'Lees meer'"
              >
              </Button>
            </footer>
          </BaseCard>
        </div>
      </div>
    </BaseContainer>
  </section>
</template>
