<script setup>
const props = defineProps({
  content: {
    type: Array,
    required: true,
    default() {
      return []
    },
  },

  grouped: {
    type: Boolean,
    required: false,
    default: false,
  },

  breadcrumbs: {
    type: Array,
    required: false,
    default() {
      return []
    },
  },
})

const groupedSections = computed(() => groupSections(props.content))

function getComponent(componentName) {
  switch (componentName) {
    case 'SectionsBlog':
      return resolveComponent('SectionsBlog')
    case 'SectionsCallToAction':
      return resolveComponent('SectionsCallToAction')
    case 'SectionsCards':
      return resolveComponent('SectionsCards')
    case 'SectionsContact':
      return resolveComponent('SectionsContact')
    case 'SectionsFacts':
      return resolveComponent('SectionsFacts')
    case 'SectionsFaq':
      return resolveComponent('SectionsFaq')
    case 'SectionsFeature':
      return resolveComponent('SectionsFeature')
    case 'SectionsGrouped':
      return resolveComponent('SectionsGrouped')
    case 'SectionsHero':
      return resolveComponent('SectionsHero')
    case 'SectionsIncentive':
      return resolveComponent('SectionsIncentive')
    case 'SectionsList':
      return resolveComponent('SectionsList')
    case 'SectionsLogos':
      return resolveComponent('SectionsLogos')
    case 'SectionsPlans':
      return resolveComponent('SectionsPlans')
    case 'SectionsProfessionalReferral':
      return resolveComponent('SectionsProfessionalReferral')
    case 'SectionsQuotes':
      return resolveComponent('SectionsQuotes')
    case 'SectionsSlides':
      return resolveComponent('SectionsSlides')
    case 'SectionsSteps':
      return resolveComponent('SectionsSteps')
    case 'SectionsTeam':
      return resolveComponent('SectionsTeam')
    case 'SectionsTestimonials':
      return resolveComponent('SectionsTestimonials')
    case 'SectionsText':
      return resolveComponent('SectionsText')
    case 'SectionsForm':
      return resolveComponent('SectionsForm')
    default:
      return null
  }
}

function componentName(component) {
  if (component.grouped) return 'SectionsGrouped'

  const name = component.__component
  const camelizedString = name.replace(/\W+(.)/g, (_, chr) => chr.toUpperCase())

  return camelizedString.charAt(0).toUpperCase() + camelizedString.slice(1)
}

function componentId(component) {
  if (component.grouped) return 'grouped'

  const string = component.__component.replace('sections.', '')
  const id = component?.id || null

  return string + (id ? `-${id}` : '')
}

function groupSections(sections) {
  if (props.grouped) return sections

  const sectionsArray = []
  let previousSection = null
  let indexToMerge = null
  let groupCounter = 0

  sections.forEach((section, index) => {
    if (
      (previousSection &&
        previousSection.__component === section.__component &&
        previousSection?.style === section?.style) ||
      (previousSection &&
        previousSection?.__component === 'sections.hero' &&
        section.__component === 'sections.plans') ||
      (previousSection &&
        previousSection?.__component === 'sections.hero-plans' &&
        section.__component === 'sections.plans')
    ) {
      let children = []

      if (previousSection?.grouped) {
        children = [...previousSection.children, section]
      } else {
        children = [previousSection, section]
        indexToMerge = index - (1 + groupCounter)
      }

      const name =
        (previousSection.__component === 'sections.hero' &&
          section.__component === 'sections.plans') ||
        previousSection?.__component === 'sections.hero-plans'
          ? 'sections.hero-plans'
          : section.__component

      const mergedSection = {
        __component: name,
        grouped: true,
        style: previousSection?.style,
        children,
      }

      sectionsArray[indexToMerge] = mergedSection
      previousSection = mergedSection
      groupCounter++
    } else {
      sectionsArray.push(section)
      previousSection = section
      indexToMerge = null
    }
  })

  return sectionsArray
}
</script>

<template>
  <div>
    <component
      :is="getComponent(componentName(section))"
      v-for="(section, index) of groupedSections"
      :id="componentId(section)"
      :key="index"
      :data-component="section.__component"
      :content="section"
      :grouped="grouped || null"
      :first-is-hero="
        content?.[0]?.__component === 'sections.hero' && index === 0
      "
      :breadcrumbs="
        section.__component === 'sections.hero' ||
        section.__component === 'sections.hero-plans'
          ? breadcrumbs
          : null
      "
    />
  </div>
</template>
