export function useHelpers() {
  function splitToChunks(array: Array<any>, parts: number) {
    const arrayClone = [...array]
    const result = []
    for (let i = parts; i > 0; i--) {
      result.push(arrayClone.splice(0, Math.ceil(arrayClone.length / i)))
    }
    return result
  }

  return {
    splitToChunks,
  }
}
