<script setup>
defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
})

const quote = ref(0)
</script>

<template>
  <section class="overflow-hidden bg-secondary">
    <BaseContainer class="my-10 lg:my-20">
      <Carousel
        v-if="content?.items && content?.items.length"
        v-model="quote"
        :autoplay="content.items.length > 1 ? 5000 : 0"
        :wrap-around="content.items.length > 1"
        :touch-drag="content.items.length > 1"
        :mouse-drag="content.items.length > 1"
        pause-autoplay-on-hover
      >
        <Slide
          v-for="(item, index) in content?.items"
          :key="index"
          class="px-10"
          :class="{
            'cursor-grab': content.items.length > 1,
          }"
        >
          <div
            class="flex flex-col items-center gap-x-10 md:flex-row"
            :index="index + 1"
          >
            <figure
              class="relative h-[320px] w-full max-w-full overflow-hidden rounded-t-2xl md:hidden"
            >
              <BaseVisual
                :file="item?.visual"
                class="w-full bg-white"
                size="medium"
                cover
                :width="item?.visual?.ext === '.svg' ? '100%' : null"
                :height="item?.visual?.ext === '.svg' ? '100%' : null"
              />
            </figure>
            <figure
              class="relative hidden h-[380px] w-full max-w-full overflow-hidden rounded-2xl md:flex md:h-[380px] md:max-w-[256px] lg:h-[485px] lg:max-w-[360px] lg:rounded-3xl"
              :class="{ 'mask-br': !item.noCutout }"
            >
              <BaseVisual
                :file="item?.visual"
                class="w-full bg-white"
                size="large"
                cover
                :width="item?.visual?.ext === '.svg' ? '100%' : null"
                :height="item?.visual?.ext === '.svg' ? '100%' : null"
              />
            </figure>
            <div class="flex w-full flex-col">
              <header
                class="rounded-br-2xl bg-accent p-6 md:rounded-t-2xl md:p-12 lg:rounded-t-3xl lg:rounded-br-3xl"
              >
                <h2
                  class="text-secondary"
                  :class="{
                    'text-4xl-nlh md:text-6xl-nlh lg:text-[80px]': !item?.text,
                    'text-3xl-nlh md:text-5xl-nlh lg:text-[60px]': item?.text,
                  }"
                >
                  &quot;{{ item?.title }}&quot;
                </h2>
                <h3
                  class="mt-4 font-sans text-base text-secondary md:text-lg-nlh"
                >
                  {{ item?.text }}
                </h3>
              </header>
              <footer class="flex w-full flex-col justify-between lg:flex-row">
                <div class="flex w-full">
                  <div
                    class="flex w-full items-center justify-between gap-x-6 rounded-b-2xl bg-accent p-6 pt-0 md:p-12 lg:rounded-b-3xl lg:pt-12"
                  >
                    <div>
                      <h3
                        class="font-sans text-base text-secondary md:text-lg-nlh"
                      >
                        {{ item?.name }}
                      </h3>
                      <h4 class="font-sans text-base text-gray-600">
                        {{ item?.position }}
                      </h4>
                    </div>
                    <div
                      v-if="content?.items && content?.items.length > 1"
                      class="hidden items-center gap-x-3 md:flex"
                    >
                      <a
                        href="javascript:void(0)"
                        aria-label="Previous"
                        class="flex h-10 w-10 items-center justify-center rounded-full bg-secondary"
                        @click="quote--"
                      >
                        <IconArrow direction="left" class="text-white" />
                      </a>
                      <a
                        href="javascript:void(0)"
                        aria-label="Next"
                        class="flex h-10 w-10 items-center justify-center rounded-full bg-secondary"
                        @click="quote++"
                      >
                        <IconArrow class="text-white" />
                      </a>
                    </div>
                  </div>
                  <div class="min-w-[96px] bg-accent lg:hidden">
                    <div
                      class="flex h-full items-center rounded-tl-2xl bg-secondary p-12 lg:rounded-tl-3xl"
                    ></div>
                  </div>
                </div>
                <div class="mt-6 lg:mt-0 lg:bg-accent">
                  <div
                    class="flex lg:h-full lg:items-center lg:rounded-tl-3xl lg:bg-secondary lg:p-12"
                  >
                    <NuxtLink
                      :to="item?.link?.url"
                      class="flex items-center gap-x-3 text-sm text-accent"
                    >
                      <span class="whitespace-nowrap font-mono">
                        Lees het verhaal
                      </span>
                      <span
                        class="flex h-6 w-6 items-center justify-center rounded-full bg-accent"
                      >
                        <IconArrow class="text-secondary" />
                      </span>
                    </NuxtLink>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </Slide>
      </Carousel>
    </BaseContainer>
  </section>
</template>
