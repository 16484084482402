<script setup>
defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
})

const { formatMarkdown } = useFormatters()
</script>

<template>
  <section class="relative w-full">
    <BaseContainer class="my-10 lg:my-20">
      <div v-if="content?.title" class="lg:max-w-1/2 relative z-10">
        <header class="mb-10 text-center lg:mb-16">
          <h3
            v-if="content?.subTitle"
            class="mb-2 text-xl-nlh uppercase text-gray-500 lg:text-2xl-nlh"
          >
            {{ content?.subTitle }}
          </h3>
          <h2
            class="text-5xl-nlh uppercase leading-verytight text-secondary lg:text-7xl-nlh"
          >
            {{ content?.title }}
          </h2>
        </header>
      </div>

      <div
        v-if="content?.items && content?.items.length"
        class="grid grid-cols-2 gap-4 sm:gap-8 lg:grid-cols-4"
      >
        <div
          v-for="(item, index) of content.items"
          :key="index"
          class="flex flex-col"
        >
          <div class="relative w-fit self-center">
            <BaseVisual
              v-if="item?.icon"
              :file="item?.icon"
              class="relative z-10 flex h-[96px] w-[96px] items-center justify-center rounded-2xl bg-white p-4 text-secondary lg:h-[152px] lg:w-[152px] lg:rounded-3xl"
              :width="96"
              :height="96"
              size="thumbnail"
            />
            <div
              class="absolute bottom-0 left-0 z-10 -mb-1 flex h-10 w-10 items-center justify-center rounded-2xl bg-accent pt-1 font-heading text-xl uppercase text-secondary lg:text-2xl"
            >
              <span>{{ index + 1 }}</span>
            </div>
          </div>
          <div class="my-6 lg:my-10">
            <h4 class="text-xl-nlh uppercase text-secondary lg:text-3xl-nlh">
              {{ item?.title }}
            </h4>
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="item?.content"
              class="mt-6 leading-6 text-gray-600"
              :class="{
                'mb-6': item?.link,
                'text-sm': item?.link,
              }"
              v-html="formatMarkdown(item.content)"
            ></div>
            <!-- eslint-enable vue/no-v-html -->

            <footer v-if="item?.link" class="relative z-10 flex">
              <nuxt-link
                :to="item?.link?.url"
                class="flex items-center gap-x-3 text-sm text-secondary"
              >
                <span class="whitespace-nowrap font-mono">
                  {{ item?.link?.title }}
                </span>
                <span
                  class="flex h-6 w-6 items-center justify-center rounded-full bg-secondary"
                >
                  <IconChevronRight class="h-2 w-2 text-white" />
                </span>
              </nuxt-link>
            </footer>
          </div>
        </div>
      </div>
    </BaseContainer>
  </section>
</template>
