<script setup>
defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
})

const { formatMarkdown } = useFormatters()
</script>

<template>
  <section>
    <BaseContainer class="my-10 lg:my-20" size="md">
      <div class="rounded-2xl bg-stone-200 p-10 lg:rounded-3xl">
        <header v-if="content?.title || content?.subTitle" class="mb-3">
          <h2
            v-if="content?.title"
            class="text-4xl-nlh uppercase leading-verytight text-secondary lg:text-6xl-nlh"
          >
            {{ content?.title }}
          </h2>
        </header>

        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="content?.content"
          class="text-gray-800"
          v-html="formatMarkdown(content.content)"
        ></div>
        <!-- eslint-enable vue/no-v-html -->

        <BaseContactForm
          v-if="content.formEnabled"
          class="mt-12"
          :config="{
            form: 'contact',
            recipient: content.recipient,
            fields: {
              name: {
                label: 'Naam',
                type: 'text',
                validation: 'required',
                placeholder: 'Naam',
              },
              company: {
                label: 'Bedrijf',
                type: 'text',
                placeholder: 'Bedrijf',
              },
              email: {
                label: 'E-mail',
                type: 'email',
                validation: 'required|email',
                placeholder: 'E-mail',
              },
              phone: {
                label: 'Telefoonnummer',
                type: 'text',
                placeholder: 'Telefoonnummer',
              },
              message: {
                label: 'Bericht',
                type: 'textarea',
                validation: 'required',
                class: { outer: 'lg:col-span-2' },
                placeholder: 'Bericht',
              },
            },
            submit: {
              label: 'Verstuur',
              type: 'submit',
              class: 'lg:col-span-2',
            },
          }"
        />
        <p v-else class="markdown prose mt-3 text-neutral-400">
          Het formulier is uitgeschakeld.
        </p>
      </div>
    </BaseContainer>
  </section>
</template>
