<script setup>
import { reset } from '@formkit/core'

const props = defineProps({
  config: {
    type: Object,
    required: false,
    default() {
      return {}
    },
  },
})

const { createForm } = useApiForm()

const loading = ref(false)
const success = ref(false)

async function submit(data) {
  loading.value = true

  try {
    await createForm({
      form: props?.config.form,
      from: data.email,
      to: props?.config.recipient,
      data,
    })

    reset(props.config.form)
    success.value = true
  } catch (error) {
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <FormKit
    :id="config.form"
    type="form"
    :actions="false"
    form-class="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:text-lg mt-12"
    @submit="submit"
  >
    <FormKit
      v-for="([key, value], index) of Object.entries(config?.fields)"
      :key="index"
      :type="value.type"
      :name="key"
      :label="false"
      :placeholder="value.placeholder"
      :validation="value.validation"
      :options="value.options || []"
      :classes="value.class"
      :validation-label="value.label"
    />

    <footer class="items-center lg:col-span-2 lg:flex">
      <Button
        theme="secondary"
        type="submit"
        size="md"
        class="text-base"
        :loading="loading"
        :text="config?.submit?.label"
      />
      <p
        v-if="success"
        class="markdown text-success-600 prose mt-4 font-medium lg:ml-8 lg:mt-0"
      >
        Uw bericht is verstuurd!
      </p>
    </footer>
  </FormKit>
</template>

<style>
.formkit-input[type='text'],
.formkit-input[type='email'],
.formkit-input[type='select'],
textarea.formkit-input {
  @apply relative flex gap-x-1 rounded-lg font-sans font-bold focus-visible:outline-offset-0;
}

.formkit-input[type='select']:before {
  @apply pointer-events-none absolute right-5 top-1/2 h-0 w-0 -translate-y-1/2;
  content: '';
  border-color: #0f172a transparent transparent;
  border-style: solid;
  border-width: 0.3em 0.3em 0;
}

input[type='text'],
input[type='email'],
textarea,
select {
  @apply w-full rounded-lg border-none px-3 py-3 text-gray-800 outline-none autofill:bg-tertiary lg:px-5 lg:py-3;
}

textarea {
  @apply min-h-[265px];
}

.formkit-input[type='select'] {
  @apply flex gap-x-1 rounded-lg autofill:bg-tertiary focus-visible:outline-offset-0;
}

select {
  @apply appearance-none bg-white pr-10 shadow;
}

.formkit-messages {
  @apply mb-0 mt-2 list-none;
}

.formkit-message {
  @apply mt-1 text-xs font-medium text-red-800 first:mt-0;
}

.formkit-outer[data-invalid='true']
  .formkit-wrapper
  .formkit-inner
  .formkit-input {
  @apply border-red-600 border-[1px] border-solid;
}
</style>
