<script setup>
defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
})

const user = useStrapiUser()
const { getProfessionalReferralUrl } = useReferrals()

const isProfessional = computed(() => user.value?.type === 'professional')
</script>

<template>
  <section v-if="isProfessional" class="relative w-full">
    <BaseContainer class="relative z-10 my-10 lg:my-20" size="md">
      <div
        class="relative flex flex-col flex-wrap items-center justify-between overflow-hidden rounded-2xl p-6 text-center sm:p-12 lg:rounded-3xl"
        :class="{
          'bg-primary': content?.style === 'Primary',
          'bg-secondary': content?.style === 'Secondary',
        }"
      >
        <div class="lg:max-w-1/2 relative z-10">
          <header v-if="content?.title" class="mb-6">
            <h2
              class="text-4xl-nlh uppercase lg:text-7xl-nlh"
              :class="{
                'text-secondary': content?.style === 'Primary',
                'text-primary': content?.style === 'Secondary',
              }"
            >
              {{ content?.title }}
            </h2>
          </header>
        </div>
        <div class="sm:1/2 lg:3/4 w-2/3">
          <UrlCopier
            :url="getProfessionalReferralUrl($strapi.user.professional)"
            class=""
          />
        </div>
      </div>
    </BaseContainer>
  </section>
</template>
