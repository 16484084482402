<script setup>
defineProps({
  content: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
})

const { formatMarkdown } = useFormatters()
</script>

<template>
  <section>
    <BaseContainer size="sm" class="my-10 lg:my-20">
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="content?.content"
        class="markdown prose"
        v-html="formatMarkdown(content.content)"
      ></div>
      <!-- eslint-enable vue/no-v-html -->
      <div
        v-if="content?.buttons"
        class="relative z-10 flex flex-wrap gap-3 sm:gap-5"
      >
        <Button
          v-for="button in content?.buttons"
          :key="button.id"
          :theme="button?.style.toLowerCase() || 'primary'"
          :path="button?.url"
          :aria-label="button?.title"
          :text="button?.title"
        />
      </div>
    </BaseContainer>
  </section>
</template>
